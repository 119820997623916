<template>
  <div>
    <v-card class="mb-5" outlined color="white">
      <v-breadcrumbs :items="items" large></v-breadcrumbs>
    </v-card>

    <v-row>
      <v-col cols="12" md="8">
        <v-card height="70vh" class="pa-2">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3989.689896062282!2d121.94486661431854!3d0.4597119639176316!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x32783434d2737545%3A0xff07c50c2c81d42a!2sInspektorat%20Daerah!5e0!3m2!1sen!2sid!4v1630080301574!5m2!1sen!2sid"
            width="100%"
            height="100%"
            style="border:0;"
            allowfullscreen=""
            loading="lazy"
          ></iframe>
        </v-card>
      </v-col>
      <v-col cols="12" md="4">
        <v-card class="mb-5">
          <v-card-title>Alamat Kantor</v-card-title>
          <v-card-text>
            Block Plan, Palopo, Marisa, Pohuwato Regency, Gorontalo 96265
          </v-card-text>
        </v-card>

        <v-card class="mb-5">
          <v-card-title>Sosial Media</v-card-title>
          <v-card-text class="ml-n2">
            <v-btn color="primary" icon>
              <v-icon>mdi-facebook</v-icon>
            </v-btn>
            <v-btn color="error" icon>
              <v-icon>mdi-gmail</v-icon>
            </v-btn>
          </v-card-text>
        </v-card>

        <v-btn
          v-if="session.session_ok"
          class="primary"
          block
          @click="dashboard()"
        >
          <v-icon left>mdi-view-dashboard</v-icon>
          Dashboard
        </v-btn>

        <v-btn v-else class="primary" block @click="login()">
          <v-icon left>mdi-lock-outline</v-icon>
          Login
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Cookie from "@/helper/cookie.js";

export default {
  name: "Kontak",

  data: () => ({
    session: "",

    items: [
      {
        text: "Home",
        disabled: false,
        href: "/",
      },
      {
        text: "Kontak",
        disabled: false,
        href: "/kontak",
      },
    ],
  }),

  async mounted() {
    this.session = await JSON.parse(Cookie.dec(Cookie.get("myCookie")));
  },

  methods: {
    login() {
      this.$router.push("/login").catch(() => {});
    },

    dashboard() {
      this.$router.push("/admin").catch(() => {});
    },
  },
};
</script>
